import React, { useState, useEffect } from "react";
import Layout from "../components/layout"
import { Link } from "gatsby";
import { Container, Col, Row } from "react-bootstrap";
import '../components/contact-book/styles/_index.scss';
import Button from "../components/button/button";

const Page404 = (props) => {
  return (
    <React.Fragment>
    <div className="errornotfound">
    <Layout>
      <section className="errorpage contact-book">
        <Container>
          <Row className="d-flex justify-content-center bd-highlight mb-3 text-center">
            <Col md="9">
              <h1>Ooops!</h1>
              <p>We can't find the page you were looking for. This is maybe because it has been removed, we've changed its name or it is temporarily unavailable.</p>
                
                <div className="contact-book__actions">
                <Button link="/property/for-sale/in-sittingbourne/" className="btn--outline-darkblue">Find a Propety </Button>
                 <Button link="/contact/"  className="btn--outline-darkblue">Contact Us</Button>
                </div>
            </Col>
          </Row>
        </Container>
      </section>
      </Layout>   
      </div>   
    </React.Fragment>
  )
}
export default Page404;
